<template>
    <div>
        <div class="top-header blackBG border-bottom">
            <div class="marquee border-top-0 border-bottom">
                <banner-slider></banner-slider>
            </div>
        </div>
        <section class="blockElement innerPage-banner bg-white">
            <div class="container">
                <div class="row">
                    <div class="col-12"> 
                        <div class="breadCrum d-flex align-items-center justify-content-between flex-wrap">
                            <div class="bdPage">
                                <h2>Technical Analysis</h2>
                                <nav aria-label="breadcrumb mt-2">
                                    <ol class="breadcrumb mb-0">
                                        <li class="breadcrumb-item"><router-link class="green" to="/dashboard">Home</router-link></li>
                                        <li class="breadcrumb-item active" aria-current="page">Technical Analysis</li>
                                    </ol>
                                </nav>
                            </div>
                            <!-- <div class="rightSearch hideMobile">
                                <form class="searchBar">
                                    <div class="form-group position-relative inputIcon mb-0">
                                        <input type="text" class="form-control" placeholder="Search.." name="" /> 
                                        <a class="searchButton" href="#"><vue-feather type="search"></vue-feather> </a>
                                    </div>
                                </form>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="blockElement allMkt newsMarket space-footer bg-white">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-lg-8 mb-4 mb-lg-0">
                        <div class="marketTitle">
                            <ul class="toplinks visible pb-2 d-flex align-items-center border-bottom allnewsTabs">
                                <li class="ps-0"><a href="javascript:void(0)" @click="marketTab = 'All';page = 1;allInsight()" :class= "[{'active' : marketTab == 'All'}]" >All</a></li>
                                <li><a href="javascript:void(0)" @click="marketTab = 'Forex';page = 1;allInsight()" :class= "[{'active' : marketTab == 'Forex'}]" >Forex</a> </li>
                                <li><a href="javascript:void(0)" @click="marketTab = 'Crypto';page = 1;allInsight()" :class= "[{'active' : marketTab == 'Crypto'}]">Crypto</a></li>
                                <li><a href="javascript:void(0)" @click="marketTab = 'Commodities';page = 1;allInsight()" :class= "[{'active' : marketTab == 'Commodities'}]">Commodities</a></li>
                                <li><a href="javascript:void(0)" @click="marketTab = 'Indices';page = 1;allInsight()" :class= "[{'active' : marketTab == 'Indices'}]">Indices</a> </li>
                                  <li><a href="javascript:void(0)" @click="marketTab = 'trending';page = 1;allInsight()" :class= "[{'active' : marketTab == 'trending'}]">Trending</a> </li>
                            </ul>
                        </div>
                        <div v-if=" tabWiseData.totalRecords ">
                            <div class="card-body d-flex align-items-start border-bottom mb-2 px-0" v-for="item,key in tabWiseData.records" >
                                <span class="mb-2 mb-md-0"><img width="300" :src="item.image_url" alt="News" title="News"></span>
                                <div class="flex-grow-1 ms-0 ms-md-3" :id="'insight'+item._id">
                                    <h6><a href="javascript:void(0)" @click=" () => goToDetailPage(item)">{{(item.source != 'FXS') ? item.metadata.header : item.title}}</a></h6>
                                    <p v-html="item.text.substring(0,200)+'...'"></p>
                                    <ul class="datTime d-flex align-items-center">
                                        <li class="me-4"><a href="javascript:void(0)" class="green">{{item.source}}</a></li>
                                        <li>{{formatNow(item.created,'now',0)}}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <no-record v-if="tabWiseData.totalRecords == 0"></no-record>
                        <div class="d-flex justify-content-center" v-if="tabWiseData.records && (tabWiseData.records.length < tabWiseData.totalRecords)">
                            <p class="grd_btn" v-if="store.loading"><i class="me-2 fa fa-spinner fa-spin"></i></p>
                            <a href="javascript:void(0);" class="grd_btn d-flex align-items-center" @click="() => loadMore()" v-else>Load More</a>
                        </div>
                    </div>
                    <div class="col-12 col-lg-4">
                        <div class="position-sticky topAdd">
                        <div class="economicAllNews mb-4 mb-lg-5">
                            <div class="d-flex justify-content-between align-items-center mb-4">
                              <h3 class="m-0">Economic Calendar</h3>
                              <router-link to="/economic-calendar" class="green_btn d-flex align-items-center">See All <vue-feather size="12px" type="chevron-right" class="chevron-right"></vue-feather></router-link>
                            </div>
                            <div v-if="economicData.length" class="table-responsive economic">
                                <table v-for="(row, indexOuter) of economicData" class="table table-sm border-0 bg-white" :class="{'border-top': indexOuter > 0}" :key="row.date">
                                    <thead>
                                        <tr role="button" @click="()=> toggleOuter(indexOuter)">
                                            <th class="date medium">{{COMMON.formatDate(row.date, "Do MMMM")}}</th>
                                            <th colspan="3" class="events text-end">{{row.items.length}}events</th>
                                        </tr>
                                    </thead>
                                    <tbody v-if="activeOuter==indexOuter">
                                        <tr
                                            role="button"
                                            v-for="(event, indexInner) of rowInnerList"
                                            @click="()=> toggleInner(indexInner, event)"
                                            class="borderAdd"
                                            :class="{active: activeInner==indexInner, rt: event.importance==1, et: event.importance==2, bt: event.importance==3,}"
                                            :key="event._id"
                                        >
                                            <td class="p-0 border-0" colspan="4">
                                                <table width="100%" class="fixed-layout nowrap">
                                                    <tr>
                                                        <td :class="{'active-td': activeInner==indexInner}">
                                                            <span class="d-flex align-items-center">
                                                                <img width="25" height="25" :src=" 'assets/images/country_flag/' + event.country.toLowerCase() + '.webp' " class="rounded-circle me-2" alt="" /><span class="ellipsis">{{event.country}}</span>
                                                            </span>
                                                        </td>
                                                        <td>{{COMMON.formatDate(event.date, "hh:mm a")}}</td>
                                                        <td>{{event.event}}</td>
                                                        <td>
                                                            <a class="d-block text-end" href="javascript:void(0);">
                                                                <vue-feather v-if="activeInner !=indexInner" type="chevron-down"></vue-feather> <vue-feather v-if="activeInner==indexInner" type="chevron-up"></vue-feather>
                                                            </a>
                                                        </td>
                                                    </tr>
                                                    <tr class="subactive-tr" v-if="activeInner==indexInner">
                                                        <td colspan="4">
                                                            <div class="d-flex align-items-center justify-content-between openDom">
                                                                <span class="subactive-td">
                                                                    <div class="f-16">{{COMMON.formatCalendarDate( activeEvent.date, "duration")}}</div>
                                                                    <div class="f-12">Time{{COMMON.formatDate( activeEvent.date, "HH:mm")}}</div>
                                                                </span>
                                                                <span align="center" class="active-time-int middle">Actual{{activeEvent.actual ? activeEvent.actual : "-"}}</span>
                                                                <span colspan="2" class="subactive-td2">
                                                                    <div class="forecast"><span class="f-12 me-2">Forecast</span> <span class="f-16">{{activeEvent.teForecast ? activeEvent.teForecast : "-"}}</span></div>
                                                                    <div class="previe"><span class="f-12 me-2">Previous</span> <span class="f-16">{{activeEvent.previous ? activeEvent.previous : "-"}}</span></div>
                                                                </span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div> 
                        </div>
                        <div class="marketTitle">
                            <div class="d-flex align-items-center justify-content-between mb-4">
                                <h3 class="mb-0">Most Read</h3>
                                <!-- <router-link to="/all-news" class="green_btn d-flex align-items-center me-3">See All <vue-feather size="15" type="chevron-right"></vue-feather></router-link> -->
                            </div>
                        </div>
                        <div v-if=" store.ReadfinancialContent.totalRecords &&  store.ReadfinancialContent.totalRecords > 0">
                            <ul class="mostRead" v-for="(item, key) in store.ReadfinancialContent.records.slice(0, 6)" :key="key" :id="'insight'+item._id">
                                <li v-if="item.metadata && item.metadata.header"><a href="javascript:void(0)" @click=" () => goToDetailPage(item)">{{(item.source != 'FXS') ? item.metadata.header : item.title}}</a></li>
                            </ul>
                        </div>
                        <no-record v-else></no-record>
                    </div>
                </div>
                </div>
            </div>
        </section>
        <!-- Open Modal -->
           <!--  <div class="modal fade ideaModal" v-if="id != ''" :class="(id != '') ? 'show' : ''">
              <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div class="row modal-content g-0 h-100">
                    <div class="col-12 col-lg-6 ideaChart">
                        <img class="d-block w-100 h-100" :src="id_data(id).image_url" alt="News" title="News Image">
                    </div>  
                    <div class="col-12 col-lg-6 d-flex flex-column ideaChart border-start">
                        <div class="modal-body p-4 pt-0 border-0">
                            <div class="d-flex align-items-center justify-content-between modalHeader mb-3 py-3">
                                <ul class="d-flex align-items-center mb-0">
                                    <li><a href="javascript:void(0)" class="d-flex me-3"><vue-feather size="23px" type="share-2"></vue-feather></a></li>
                                    <li><a href="javascript:void(0)" class="d-flex "><vue-feather size="23px" type="printer"></vue-feather></a></li>
                                </ul>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="id = ''"></button>
                            </div>
                            <ul class="datTime d-flex align-items-center mb-0">
                                <li class="me-4"><a class="green" href="portfolios.com">Portfolios.com </a></li>
                                <li>{{formatNow(id_data(id).created,'now',0)}}</li>
                            </ul>
                            <h2 class="medium my-4 py-2">{{(id_data(id).source != 'FXS') ? id_data(id).metadata.header : id_data(id).title}}
                            </h2>
                            <p v-html="id_data(id).text"></p>
                        </div>
                        <div class="modalFooter p-0 border-0">
                            <div class="prevNextNews border-top trade d-flex align-items-center justify-content-between">
                                <a href="javascript:void(0)" class="border-end prev d-flex align-items-center" @click="next_prev(-1)">
                                    <i class="me-2 fa fa-caret-left" aria-hidden="true"></i>Previous
                                </a>
                                <a href="javascript:void(0)" class="border-start prev d-flex align-items-center" @click="next_prev(1)">
                                    Next <i class="ms-2 fa fa-caret-right" aria-hidden="true"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
              </div>
            </div> -->
        <!-- End -->
    </div>
</template>
<script>
import bannerslider from "../../components/shared/banner_slider";
import { useMainStore } from "@/store";
import moment from 'moment';
export default {
    setup() {
        const store = useMainStore();
        return { store };
    },
    data() {
        return {
            id:'',
            perPage : 6,
            marketTab : 'All',
            slice:6,
            dropDown:false,
            activeOuter: 0,
            activeInner: 0,
            page : 1
        }
    },
    watch : {
        // 'id'(val){
        //     const el = document.body;
        //     if(val){
        //         if (el.classList.contains('position-fixed')) {
        //           el.classList.remove('position-fixed');
        //         } else {
        //           el.classList.add('position-fixed');
        //         }
        //     }else{
        //         el.classList.remove('position-fixed');
        //     }
        // }
    },
    computed:{
        tabWiseData(){
            if(this.marketTab == 'trending'){
                return this.store.treandingInsight
            }else {
                return this.store.financialContent
            }
        },
        dateAdjustedEvents() {
          if (Object.keys(this.store.calendarData).length) {
            let temp = [],
              allEvents = [];
            for (let key in this.store.calendarData) {
              this.store.calendarData[key].items.map((i) => {
                let obj = Object.assign({}, i);
                obj.date = this.COMMON.formatCalendarDate(
                  obj.date,
                  "YYYY-MM-DD HH:mm",
                  0
                );
                //console.log(obj);
                temp.push(obj);
              });
            }
            temp.map((i, index) => {
              const ind = allEvents.findIndex(
                (j) =>
                  moment(j.date).format("YYYY-MM-DD") ==
                  moment(i.date).format("YYYY-MM-DD")
              );
              if (ind > -1) {
                if (allEvents[ind].items) {
                  allEvents[ind].items.push(i);
                } else {
                  allEvents[ind].items = [i];
                }
              } else {
                allEvents.push({
                  date: moment(i.date).format("YYYY-MM-DD"),
                  items: [i],
                });
              }
            });
            return allEvents;
          } else {
            return [];
          }
        },
        economicData() {
          if (this.dateAdjustedEvents.length) {
            let arr = [];
            this.dateAdjustedEvents.map((i) => {
              let temp = {
                date: i.date,
                items: [],
              };
              if (i.items.length) {
                temp.items = i.items.filter((j) => {
                  return (
                    moment(
                      this.COMMON.formatDate(j.date, "YYYY-MM-DD HH:mm")
                    ).diff(moment(), "minute") > 5
                  );
                });
              }
              if (temp.items.length) arr.push(temp);
            });
            return arr.slice(0, 5);
            // return arr;
          } else {
            return [];
          }
        },
        rowInnerList() {
          if (
            this.activeOuter > -1 &&
            this.economicData[this.activeOuter].items &&
            this.economicData[this.activeOuter].items.length
          ) {
            let arr = [];

            arr = this.economicData[this.activeOuter].items.filter((j) => {
              return (
                j.importance > 1 &&
                moment(
                  this.COMMON.formatDate(j.date, "YYYY-MM-DD HH:mm")
                ).diff(moment(), "minute") > 5
              );
            });
            return arr.slice(0, 5);
          } else {
            return [];
          }
        },
        activeEvent() {
          if (this.activeOuter > -1 && this.activeInner > -1)
            return this.rowInnerList[this.activeInner];
        },
    },
    methods:{
        toDivElement(id){
            try{
                setTimeout(function(){
                    var elmnt = document.getElementById(id);
                    elmnt.scrollIntoView({behavior: "smooth"});
                    document.getElementById(id).focus();
                    //var top = $(window).scrollTop()
                    // console.log(id,'top',top)
                    // $(window).scrollTop(top-80)
                }, 500);
            }catch(e){}
        },
        goToDetailPage(item){
            let title = ''
            if(item.source != 'FXS'){
                title = item.metadata.header
            }else{
                title = item.title
            }
            if(title.includes('-')){
                title = title.replace(/-+/g, '')
            }else{
                let titl = title.replace(/[&\/\\#,+()$~%.'":*?<>{}“” ]/g, '-')
                if(titl.includes('--')){
                    title = titl.replace(/--+/g, '-')
                }else{
                    if(item.source != 'FXS'){
                        title = item.metadata.header
                    }else{
                         title = item.title
                    }
                }
            }
            this.$router.push({
                name: 'insight_detail',
                params : {'id' : item._id,'title':title.toLowerCase().replace(/[&\/\\#,+()$~%.'":*?<>{}“” ]/g, '-'),'page' : this.page}
            });
        },
        formatNow(date,format,offset){
            if(!date) return 'N/A'
            let d = moment(date, 'YYYY-MM-DD HH:mm:ss')
            let tz = offset != 0 ? offset : -(new Date().getTimezoneOffset())
            d.add(tz, 'minutes')
            return d.fromNow()
        },
        calinsightsDetail(){
            let form ={
                '_id' : this.id
            }
            this.store
          .callFinancialContentDetail(form, false)
          .then((response) => {
          })
          .catch();
        },
        id_data(id){
            return this.store.allMarketInsights.find(val=>val._id == id) || {}
        },
        id_index(id){
            return this.store.allMarketInsights.findIndex(val=>val._id == id)
        },
        toggleInner(index, event) {
          if (this.activeInner == index) {
            this.activeInner = -1;
            this.activeEvent = {};
          } else {
            this.activeInner = index;
            this.activeEvent = event;
          }
        },
        next_prev(to){
            //console.log('return',this.id_index(this.id)+to)
            if((this.id_index(this.id)+to) < 0){
                this.id = this.store.allMarketInsights[this.store.allMarketInsights.length - 1]._id
            }else if(this.store.allMarketInsights.length == this.id_index(this.id)+to){
                this.id = this.store.allMarketInsights[0]._id
            }else{
                this.id = this.store.allMarketInsights[this.id_index(this.id)+to]._id
            }
            //console.log('4',this.store.allMarketInsights[4]._id)
        },
        toggleOuter(index) {
          if (this.activeOuter == index) {
            this.activeOuter = -1;
            this.activeInner = -1;
          } else {
            this.activeOuter = index;
            this.activeInner = -1;
          }
        },
        getCalendar(){
            let formData = {
              page: 1,
              perPage: 15,
            };
            this.store
          .getCalendar(formData, false)
          .then(() => {
          })
          .catch();
        },
        loadMore(){
            this.page += 1
            this.allInsight('loadmore')
        },
        mostRead(){
            let formData = {
              page: 1,
              perPage: 15,
              type : 'most_read'
            };
            this.store
              .callFinancialContent(formData, true)
              .then(() => {})
              .catch((err) => console.log(err.message));
        },
        allInsight(from){
            if(!from){
                this.$router.replace({
                    path: this.$route.path,
                    query: {'type' :this.marketTab},
                });
            }
            let formData = {
                  page: this.page,
                  perPage: parseInt(this.perPage),
                };
                if(this.marketTab == 'Forex'){
                formData['category'] = 'Forex'
                }else if(this.marketTab == 'All'){
                    formData['category'] = 'All'
                }else if(this.marketTab == 'Crypto'){
                    formData['category'] = 'Crypto'
                }else if(this.marketTab == 'Indices'){
                    formData['category'] = 'Indices'
                }else if(this.marketTab == 'Commodities'){
                    formData['category'] = 'Commodities'
                }else if(this.marketTab == 'Bonds'){
                    formData['category'] = 'Bonds'
                }
                if(this.marketTab == 'trending'){
                    this.store
                  .callTreandingFinancialContent(formData, true)
                  .then(() => {})
                  .catch((err) => console.log(err.message));
                }else{
                  this.store
                  .callFinancialContent(formData, true)
                  .then(() => {})
                  .catch((err) => console.log(err.message));
                }
        }
    },
    components: {
        bannerSlider:bannerslider,

    },
    mounted(){
        if(this.$router.options.history.state.forward && this.$router.options.history.state.forward.split('/')[1] == 'insight-detail'){
            let data = this.$router.options.history.state.forward.split('/');
            this.toDivElement('insight'+data[2])
            this.$router.options.history.state.forward = ""
            this.page = parseInt(data[4])
        }else{
            this.allInsight()
        }
    },
    created(){
        if(this.$route.query.type){
            this.marketTab = this.$route.query.type
        }
        this.getCalendar()
        this.mostRead()
    }, 

}
</script>
